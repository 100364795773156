import {Component, Input, OnInit} from '@angular/core';
import {PdvService} from "../../../services/http/pdv/pdv.service";
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-users-company-table',
    templateUrl: './users-company-table.component.html',
    styleUrls: ['./users-company-table.component.scss']
})
export class UsersCompanyTableComponent implements OnInit {
    @Input() pdv: any
    @Input() showCommissions: boolean = false;

    constructor(
        public authService: AuthService) {
    }

    ngOnInit(): void {
    }

    toolTipTitle(origin: string): string{
        return "Email heredado de " + origin;
    }
    formatDate = (date: string): Date => {
        return new Date(date)
    }

}
