import { Injectable } from '@angular/core';
import { pageLimit } from 'src/app/shared/constantes';
import { AuthService } from '../../auth.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { IListPaginator } from 'src/app/core/models/lists.models';

@Injectable({
  providedIn: 'root'
})
export class Reset2faService extends ApiService{
  private paginator: IListPaginator = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};

  constructor(
    auth: AuthService,
    http: HttpClient,
) {
    super(auth, http);
}

  public checkUserReset2fa(){
    var info = this.auth.getUserInfo();
    if (info?.username == 'josemanuel.penin') {
      return true;
    }
    return false;
  }

  

  searchUser(username:string) {
    return this.http.post(environment.digoApiEndpoint + 'user/reset2fa/search', {username: username}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
  }

  public resetUser2fa(username:string, numTask:string): Observable<any> {
    return this.http.post<any>(environment.digoApiEndpoint + 'user/reset2fa', {username: username, num_task: numTask}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
  }

}
