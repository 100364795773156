import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HerramientasRoutingModule } from './herramientas-routing.module';
import { ListadoComponent } from './logistica/pedidos/listado/listado.component';
import {SharedModule} from "../../shared/shared.module";
import {ListsModule} from "../../core/lists/lists.module";
import { ListadoArticulosComponent } from './logistica/articulos/listado-articulos/listado-articulos.component';
import { ListadoGruposComponent } from './logistica/grupos/listado-grupos/listado-grupos.component';
import { StockArticulosComponent } from './logistica/articulos/stock-articulos/stock-articulos.component';
import { ArticulosComponent } from './general/articulos/articulos.component';
import { ReportsComponent } from './reports/reports.component';
import { DetallePedidoLogisticaComponent } from './logistica/pedidos/detalle-pedido-logistica/detalle-pedido-logistica.component';
import { DetalleGrupoLogisticaComponent } from './logistica/grupos/detalle-grupo-logistica/detalle-grupo-logistica.component';
import { InformesComponent } from './informes/informes.component';
import { InformesListadoComponent } from './informes/informes/informes-listado/informes-listado.component';
import { InformesSolicitadosComponent } from './informes/informes-solicitados/informes-solicitados.component';
import { InformesDiariosComponent } from './informes/informes-diarios/informes-diarios.component';
import { SuscripcionInformesComponent } from './informes/suscripcion-informes/suscripcion-informes.component';
import { ModalSuscripcionComponent } from './informes/suscripcion-informes/modal-suscripcion/modal-suscripcion.component';
import {CustomPipesModule} from "../../core/custom-pipes/custom-pipes.module";
import { PermisosComponent } from './permisos/permisos.component';
import { ConfiguradorPermisoComponent } from './permisos/configurador-permiso/configurador-permiso.component';
import { MenuComponent } from './menu/menu.component';
import { LoadIframeComponent } from './load-iframe/load-iframe.component';
import { NewPedidoLogisticaComponent } from './logistica/pedidos/new-pedido-logistica/new-pedido-logistica.component';
import { ModalsNewPedidoLogisticaComponent } from './logistica/pedidos/new-pedido-logistica/modals-new-pedido-logistica/modals-new-pedido-logistica.component';
import { DesbloqueoAccesoComponent } from './desbloqueo-acceso/desbloqueo-acceso.component';
import { BlockUsersComponent } from './bloquear-usuarios/block-users/block-users.component';
import { LogBlockUsersComponent } from './bloquear-usuarios/log-block-users/log-block-users.component';
import { Reset2faComponent } from './reset2fa/reset2fa.component';
import { ModalAddBlockedUserComponent } from 'src/app/shared/components/modal-add-blocked-user/modal-add-blocked-user.component';
import { ModalEditBlockedUserComponent } from 'src/app/shared/components/modal-edit-blocked-user/modal-edit-blocked-user.component';


@NgModule({
    declarations: [
        ListadoComponent,
        ListadoArticulosComponent,
        ListadoGruposComponent,
        StockArticulosComponent,
        ArticulosComponent,
        ReportsComponent,
        DetallePedidoLogisticaComponent,
        DetalleGrupoLogisticaComponent,
        InformesComponent,
        InformesListadoComponent,
        InformesSolicitadosComponent,
        InformesDiariosComponent,
        SuscripcionInformesComponent,
        ModalSuscripcionComponent,
        PermisosComponent,
        ConfiguradorPermisoComponent,
        MenuComponent,
        LoadIframeComponent,
        NewPedidoLogisticaComponent,
        ModalsNewPedidoLogisticaComponent,
        DesbloqueoAccesoComponent,
        BlockUsersComponent,
        LogBlockUsersComponent,
        Reset2faComponent,
        ModalAddBlockedUserComponent,
        ModalEditBlockedUserComponent,
    ],
    imports: [
        CommonModule,
        HerramientasRoutingModule,
        SharedModule,
        ListsModule,
        CustomPipesModule,
    ]
})
export class HerramientasModule { }
