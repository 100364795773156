<div class="wrapper-table">
    <div class="header-table">
        <span class="font s-16 title">PDV: {{ pdv.denominacion ?? pdv.pdv_name }}</span>
        <app-modal-add-user [pdv]="pdv"></app-modal-add-user>
        <span class="tag orange">
            <span class="text-first">{{ pdv.id_pdv }}</span>
            <i nz-tooltip nzTooltipTitle="ID" nz-icon nzType="info-circle" nzTheme="outline"></i>
        </span>
    </div>
    <div class="head-table" [class.with-commissions]="showCommissions">
        <div class="th-large alias">
            <span class="font s-14 c-black">Usuario (alias)</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">Contraseña</span>
        </div>
        <div class="th-large email">
            <span class="font s-14 c-black">Email</span>
        </div>
        <div class="th-large commissions" *ngIf="showCommissions && pdv.commissions == '1'">
            <span class="font s-14 c-black">Comisiones</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">Fecha creación</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">Último acceso</span>
        </div>
        <div class="th-medium">
            <span class="font s-14 c-black">Accesos</span>
        </div>
        <div class="th-large status">
            <span class="font s-14 c-black">Estado</span>
        </div>
        <div class="th-end" *ngIf="authService.userCan('users.editar')">
        </div>
    </div>
    <ng-container *ngIf="pdv.users && pdv.users.length > 0">
        <div class="card-table" [class.with-commissions]="showCommissions" *ngFor="let user of pdv.users">
            <div class="th-large alias">
                <span class="font s-14 w-bold c-black">{{ user.username }}</span><br>
                <span class="font s-12 s-italic c-light-gray">({{ user.alias ?? "Usuario genérico" }})</span>
            </div>
            <div class="th-large">
                <span class="font s-14 c-black">{{ user.password }}</span>
            </div>
            <div class="th-large email">
                <span *ngIf="user.legacy_origin" class="font s-14 c-black">{{ user.legacy_email }}
                    <i
                        nz-icon nzType="branches"
                        class="icon c-default"
                        nzTheme="outline"
                        [nzTooltipTitle]="toolTipTitle(user.legacy_origin)"
                        nzTooltipPlacement="top"
                        nz-tooltip
                ></i></span>
                <span *ngIf="!user.legacy_origin" class="font s-14 c-black">{{ user.email ? user.email : "-" }}</span>
            </div>
            <div class="th-large commissions" *ngIf="showCommissions && pdv.commissions == '1'">
                <span class="font s-14 c-black">{{ (user.user_commissions == '1') ? 'Permite' : 'Bloqueado' }}</span>
            </div>
            <div class="th-large">
                <span class="font s-14 c-black">{{ formatDate(user.created_date) | date: 'dd/MM/yyyy' }}</span>
            </div>
            <div class="th-large">
                <span class="font s-14 c-black">{{ user.last_access ? (formatDate(user.last_access) | date: 'dd/MM/yyyy - HH:mm') : '-'}}</span>
            </div>
            <div class="th-medium">
                <span class="font s-14 c-black">{{ user.total_accesses ?? '--' }}</span>
            </div>
            <div class="th-large status">
                <div class="flex-content a-base margins m-t-15">
                    <div class="dot margins m-r-10" [ngClass]="user.status ? 'c-amena' : 'c-danger'"></div>
                    <p class="font s-14 c-black margins m-r-30">{{ user.status ? 'Activo' : 'Inactivo' }}</p>
                </div>
            </div>
            <div class="th-end" *ngIf="authService.userCan('users.editar')">
                <a nz-dropdown [nzDropdownMenu]="menu" class="icon c-default size-18">
                    <i nz-icon nzType="more" nzTheme="outline"></i>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                    <li nz-menu-item>
                        <app-modal-change-user [pdv_name]="pdv.denominacion ?? pdv.pdv_name" [user]="user"></app-modal-change-user>
                    </li>
                    <li nz-menu-item>
                        <app-modal-change-email [pdv_name]="pdv.denominacion ?? pdv.pdv_name" [user]="user"></app-modal-change-email>
                    </li>
                    <li nz-menu-item *ngIf="user.status">
                        <app-modal-change-password [user]="user"></app-modal-change-password>
                    </li>
                    <li nz-menu-item *ngIf="showCommissions && pdv.commissions == '1'">
                        <app-modal-change-user-commission [user]="user"></app-modal-change-user-commission>
                    </li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item [nzDanger]="user.status">
                        <app-modal-confirm-delete [user]="user"></app-modal-confirm-delete>
                    </li>
                </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="pdv.users == null || pdv.users.length == 0">
        <div class="card-table">
            <div class="th-full">
                <div class="flex-content a-base margins a-center m-t-15">
                    <p class="font s-14 c-black margins m-r-30">No hay usuarios</p>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- <div class="card-table">
        <div class="th-large">
            <span class="font s-14 w-bold c-black">j8932934</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">Encriptada</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">19/04/2022</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">19/04/2022 - 10:00</span>
        </div>
        <div class="th-large">
            <span class="font s-14 c-black">12</span>
        </div>
        <div class="th-large">
            <div class="flex-content a-base margins m-t-15">
                <div class="dot c-amena margins m-r-10"></div>
                <p class="font s-14 c-black margins m-r-30">Activo</p>
            </div>
        </div>
        <div class="th-end">
            <a nz-dropdown [nzDropdownMenu]="menu" class="icon c-default size-18">
                <i nz-icon nzType="more" nzTheme="outline"></i>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                <li nz-menu-item>
                    <i nz-icon nzType="edit" nzTheme="outline" class="margins m-r-10"></i>
                    Cambiar usuario
                </li>
                <li nz-menu-item>
                    <i nz-icon nzType="edit" nzTheme="outline" class="margins m-r-10"></i>
                    Cambiar contraseña
                </li>
                <li nz-menu-item nzDanger>
                    <i nz-icon nzType="delete" nzTheme="outline" class="margins m-r-10"></i>
                    Desactivar
                </li>
            </ul>
            </nz-dropdown-menu>
        </div>
    </div> -->
</div>
