import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { IListFilter, IListPaginator, ITableDigo } from 'src/app/core/models/lists.models';
import { Reset2faService } from 'src/app/services/http/herramientas/reset2fa.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-reset2fa',
  templateUrl: './reset2fa.component.html',
  styleUrls: ['./reset2fa.component.scss']
})
export class Reset2faComponent implements OnInit {
  public error: string | boolean = false;
  public loading: boolean = false;
  public filters!: IListFilter[];
  public table: ITableDigo | null = null;
  public orderBy: any;
  public showUserDetail: boolean = false;
  public userDetail: string = '';
  public showAddUser: boolean = false;

  
  reset2faForm!: FormGroup;

  public paginator: IListPaginator = {
    limit: 5,
    totalPages: 1,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private reset2faService: Reset2faService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: ModalService
    ) 
    { }

  ngOnInit() {
    if (!this.reset2faService.checkUserReset2fa()) {
      this.goHome();
    }
    
    this.reset2faForm = this.fb.group({
        username: [null, [Validators.required]],
        numTask: [null, [Validators.required]]
    });

  }

  public goHome(){
    this.router.navigate((['/'])).then()
  }

  submitForm(): void {
    this.reset2faForm.markAllAsTouched();
    this.reset2faForm.updateValueAndValidity();

    if (this.reset2faForm.valid) {
      
      this.reset2faService.searchUser(this.reset2faForm.value.username).subscribe({
        next: (resp: any) => {
          console.log("RESP" + resp);
            if (resp && resp?.username) {             
              let userData = resp.username + ' - ' + resp.twoofa_phone_number + '<br/>¿Seguro que quieres resetear los datos de 2FA?';       
              let title = 'Recuerda, la solicitud debe ser realizada por parte de un supervisor de Orange'; 
              this.modalService.confirm(title, userData)
              .pipe(
                  take(1),
              ).subscribe(result => {
                  if(result) {
                      this.loading = true;
                      this.reset2fa();
                  } else {
                  }
              })
            } else {
                this.modalService.error('Ocurrió un error');
            }
        }
        , error: (err: any) => {
            this.modalService.error(err.error?.error?.msg_complete);
        }
    });

    } else {
        Object.values(this.reset2faForm.controls).forEach(control => {
            if (control.invalid) {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
            }
        });
    }
  }

  reset2fa(){
    this.reset2faService.resetUser2fa(this.reset2faForm.value.username, this.reset2faForm.value.numTask).subscribe({
      next: (resp: any) => {
        // console.log("RESP" + resp);
          if (resp && resp == true) {
            
            this.modalService.success('Se reseteó correctamente');
          } else {
              this.modalService.error('Ocurrió un error');
          }
      }
      , error: (err: any) => {
          this.modalService.error(err.error?.error?.msg_complete);
      }
  });
  }

}
