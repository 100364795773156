
<div class="container-max">
    <app-breadcrumbs
        [title]="'Herramientas <span class=\'font c-primary\'>Informes</span>'"
        [breacrumbList]="['Herramientas', 'Informes']">
    </app-breadcrumbs>

    <div class="inner-content-max">
        <nz-tabset [(nzSelectedIndex)]="currentIndex" (nzSelectChange)="tabChanged($event)">
            <nz-tab nzTitle="Informes" *ngIf="auth.userCan('informes.informes')" (nzClick)="loadTab('informes')">
                <app-informes-listado (gotoIndex)="loadTab($event.tab)" *ngIf="visitedTab.includes('informes')"></app-informes-listado>
            </nz-tab>

            <nz-tab nzTitle="Informes solicitados" *ngIf="auth.userCan('informes.solicitados')" (nzClick)="loadTab('solicitados')">
                <app-informes-solicitados *ngIf="visitedTab.includes('solicitados')" [initInformesSolicitados]="changeTab.asObservable()"></app-informes-solicitados>
            </nz-tab>

            <nz-tab nzTitle="Informes diarios" *ngIf="auth.userCan('informes.diarios')" (nzClick)="loadTab('diarios')">
                <app-informes-diarios *ngIf="visitedTab.includes('diarios')"></app-informes-diarios>
            </nz-tab>

            <nz-tab nzTitle="Suscripción a informes" *ngIf="auth.userCan('informes.suscripciones')" (nzClick)="loadTab('suscripciones')">
                <app-suscripcion-informes *ngIf="visitedTab.includes('suscripciones')"></app-suscripcion-informes>
            </nz-tab>

            <!--<nz-tab nzTitle="Informes guardados">-->
                <!--<app-saved-reports  *ngIf="visitedIndex.includes(5)"></app-saved-reports>-->
            <!--</nz-tab>-->
        </nz-tabset>
    </div>
</div>

