import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListadoComponent } from "./logistica/pedidos/listado/listado.component";
import { ListadoArticulosComponent } from "./logistica/articulos/listado-articulos/listado-articulos.component";
import { ListadoGruposComponent } from "./logistica/grupos/listado-grupos/listado-grupos.component";
import { StockArticulosComponent } from "./logistica/articulos/stock-articulos/stock-articulos.component";
import { ArticulosComponent } from "./general/articulos/articulos.component";
import { ReportsComponent } from './reports/reports.component';
import { DetallePedidoLogisticaComponent } from "./logistica/pedidos/detalle-pedido-logistica/detalle-pedido-logistica.component";
import { DetalleGrupoLogisticaComponent } from "./logistica/grupos/detalle-grupo-logistica/detalle-grupo-logistica.component";
import { InformesComponent } from "./informes/informes.component";
import { PermisosComponent } from "./permisos/permisos.component";
import { MenuComponent } from "./menu/menu.component";
import { LoadIframeComponent } from './load-iframe/load-iframe.component';
import { NewPedidoLogisticaComponent } from "./logistica/pedidos/new-pedido-logistica/new-pedido-logistica.component";
import { DesbloqueoAccesoComponent } from './desbloqueo-acceso/desbloqueo-acceso.component';
import { BlockUsersComponent } from './bloquear-usuarios/block-users/block-users.component';
import { Reset2faComponent } from './reset2fa/reset2fa.component';
import { LogBlockUsersComponent } from './bloquear-usuarios/log-block-users/log-block-users.component';

const routes: Routes = [

    // menu de herramientas
    {path: '', component: MenuComponent, data: {title:'Herramientas'}},

    //general
    {path: 'general/articulos', component: ArticulosComponent, data: {title:'Artículos'}},

    //logistica
    {path: 'logistica/pedidos', component: ListadoComponent, data: {title:'Logistica - Pedidos'}},
    {path: 'logistica/pedidos/new', component: NewPedidoLogisticaComponent, data: {title:'Logistica - Pedido nuevo'}},
    {path: 'logistica/pedidos/:id', component: DetallePedidoLogisticaComponent, data: {title:'Logistica - Detalle pedido'}},
    {path: 'logistica/grupos/:id', component: DetalleGrupoLogisticaComponent, data: {title:'Logistica - Detalle Grupos'}},
    {path: 'logistica/articulos', component: ListadoArticulosComponent, data: {title:'Logistica - Artículos'}},
    {path: 'logistica/grupos', component: ListadoGruposComponent, data: {title:'Logistica - Grupos'}},
    {path: 'logistica/stock-articulos', component: StockArticulosComponent, data: {title:'Logistica - Stock'}},

    // Informes
    {path: 'informes-demo', component: ReportsComponent, data: {title:'Informes - Demo'}}, //maquetacion
    {path: 'informes', component: InformesComponent, data: {title:'Informes'}},
    {path: 'informes/:tabid', component: InformesComponent, data: {title:'Informes - Detalle'}},

    // permisos
    {path: 'permisos', component: PermisosComponent, data: {title:'Permisos'}},

    // Bloquear Usuarios
    {path: 'user/block/current', component: BlockUsersComponent, data: {title:'Bloqueo usuarios'}},
    {path: 'user/block/historic', component: LogBlockUsersComponent, data: {title:'Histórico de Bloqueos usuarios'}},
    
    // Reset 2FA
    {path: 'user/reset2fa', component: Reset2faComponent, data: {title:'Reset 2FA'}},

    // Dinamizacion
    {path: 'dinamizacion', loadChildren: () => import('./dinamizacion/dinamizacion.module').then(m => m.DinamizacionModule), data: {title:'Dinamización'}},

    {path: ':label/:location', component: LoadIframeComponent, data: {title:'Legacy'}, runGuardsAndResolvers: 'always'},

    // Desbloqueo
    { path: 'desbloqueo', component: DesbloqueoAccesoComponent, data: {title:'Desbloquear acceso'} },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HerramientasRoutingModule { }
