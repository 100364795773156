import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PermissionsService} from "../../../../services/http/herramientas/permissions.service";
import {lastValueFrom} from "rxjs";
import {AccessService} from "../../../../services/access.service";

@Component({
    selector: 'app-configurador-permiso',
    templateUrl: './configurador-permiso.component.html',
    styleUrls: ['./configurador-permiso.component.scss']
})
export class ConfiguradorPermisoComponent implements OnInit, OnChanges {
    @Input() selectedPermission: any = null;
    @Input() screenWidth: number = 0;

    public loadingList = false;
    public loadingFilters = false;
    public listError = '';
    public searchedList: any[] = [];
    public entityList: any[] = [];
    public selected_pass_list: number[] = [];
    public notResult: boolean = false;
    public filters: any = {
        values: [],
        distributor: {
            list: [],
            value: null,
            label: ''
        },
        group: {
            list: [],
            value: null,
            label: ''
        },
        merchan: {
            list: [],
            value: null,
            label: ''
        },
        company: {
            list: [],
            value: null,
            label: ''
        },
        pdv: {
            list: [],
            value: null,
            label: ''
        },
        users: {
            list: [],
            value: null,
            label: ''
        }
    }
    public initialFilters: any = this.filters;
    public profile: string = '';
    public generalValue = ''

    public visible = false;


    constructor(
        private permissionsService: PermissionsService,
        private access: AccessService
    ) {
    }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/permisos');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedPermission'].previousValue && changes['selectedPermission'].currentValue) {
            this.reset();
        }
    }

    reset() {
        this.notResult = false;
        this.profile = '';
        this.listError = '';
        this.entityList = [];
        this.selected_pass_list = [];
        this.resetFilters();
    }

    resetFilters(reloadFilterLists: boolean = false) {
        this.filters = {
            values: [],
            distributor: {
                list: [],
                value: null
            },
            group: {
                list: [],
                value: null
            },
            merchan: {
                list: [],
                value: null
            },
            company: {
                list: [],
                value: null
            },
            pdv: {
                list: [],
                value: null,
            },
            users: {
                list: [],
                value: null,
                label: ''
            }
        }

        document.querySelectorAll('.checkbox-pass-value').forEach((el: any) => el.checked = false);
        if (reloadFilterLists) {
            this.cascadeLoadFilters();
        }
    }

    setProfile(profile: string) {
        this.reset();
        this.profile = profile;
        if (this.profile === 'general') {
            this.loadList();
        } else {
            if (!['', 'general', 'distributor'].includes(this.profile)) {
                this.open();
            }
        }

    }

    doSearch() {
        if (!this.canQueryPermissions()) {
            this.entityList = [];
            if (!['', 'general', 'distributor'].includes(this.profile)) {
                this.open();
            }
            return;
        }
        this.close();
        if (this.profile) {
            this.loadList();
        }
    }


    async loadFilters() {
        return new Promise(async (resolve, reject) => {
            let res: any = null;
            const filters: any = {};
            let key = '';
            switch (this.profile) {
                case 'distributor':
                    res = await lastValueFrom(this.permissionsService.getDistributors(this.applyFilters()));
                    key = 'distributors';
                    break;
                case 'group':
                    res = await lastValueFrom(this.permissionsService.getGroups(this.applyFilters()));
                    key = 'groups';
                    break;
                case 'merchan':
                    res = await lastValueFrom(this.permissionsService.getMerchans(this.applyFilters()));
                    key = 'merchans';
                    break;
                case 'company':
                    res = await lastValueFrom(this.permissionsService.getCompanies(this.applyFilters()));
                    key = 'companies';
                    break;
                case 'pdv':
                    res = await lastValueFrom(this.permissionsService.getPdvs(this.applyFilters()));
                    key = 'pdvs';
                    break;
                case 'user':
                    res = await lastValueFrom(this.permissionsService.getUsers(this.applyFilters()));
                    key = 'users';
                    break;
            }
            if (res) {
                console.log("loadFilters", res, filters);
                this.searchedList = res;
                filters[key] = [];
                res.forEach((item: any) => filters[key].push(item.id))
            }
            const oldFilters = this.applyFilters();
            if (oldFilters.id_pass_values) {
                filters.id_pass_values = oldFilters.id_pass_values
            }
            resolve(filters);
        })

    }

    async loadList() {
        this.loadFilters().then(filters => {
            this.loadingList = true;
            this.listError = '';
            this.permissionsService.getFilteredPermissionValuesList(this.profile, this.selectedPermission.id_pass, filters).subscribe({
                next: (permissions) => {
                    this.entityList = permissions;
                    this.selected_pass_list.length = this.entityList.length;
                    this.notResult = Boolean(!this.entityList.length);
                    if (this.profile === 'general') {
                        this.generalValue = typeof this.entityList[0] !== 'undefined' ? this.entityList[0].id_pass_value : '';
                    }
                },
                error: (err) => {
                    this.listError = 'Ocurrió un error'
                }
            }).add(() => this.loadingList = false)
        })

    }

    isEnoughFilteringForPdvOrCompany() {
        let enoughFilterPdvsCompanies = true;
        if (['company', 'pdv'].includes(this.profile)) {
            enoughFilterPdvsCompanies = this.filters.distributor.value || this.filters.group.value || this.filters.merchan.value || this.filters.company.value || !isNaN(parseInt(this.filters.pdv.value));
        }
        return enoughFilterPdvsCompanies;
    }

    isEnoughFilteringForUser() {
        return this.filtersSelected() && this.profile !== 'user';
    }

    canQueryPermissions() {
        return (this.isEnoughFilteringForPdvOrCompany() || this.isEnoughFilteringForUser()) && (this.filtersSelected() || ['', 'general', 'distributor'].includes(this.profile))
    }

    filtersSelected() {
        return Object.keys(this.applyFilters()).length > 0;
    }

    disabledFilterText() {
        if (!this.canQueryPermissions()) {
            return this.isEnoughFilteringForPdvOrCompany() ? 'Seleccione al menos un filtro para poder realizar la consulta' : 'Seleccione al menos un filtro de nivel para poder realizar la consulta'
        }
        return '';
    }


    applyFilters() {
        const filtersApi: any = {};
        if (this.profile != 'user' && this.filters.pdv.value !== '' && !isNaN(parseInt(this.filters.pdv.value))) {
            filtersApi.pdvs = [parseInt(this.filters.pdv.value)];
        } else {
            if (this.filters.values.length) {
                filtersApi.id_pass_values = [];
                this.filters.values.forEach((v: any) => filtersApi.id_pass_values.push(v.value))
            }

            if (this.filters.distributor.value) {
                filtersApi.distributors = [this.filters.distributor.value]
            }

            if (this.filters.group.value) {
                filtersApi.groups = [this.filters.group.value]
            }

            if (this.filters.merchan.value) {
                filtersApi.merchans = [this.filters.merchan.value]
            }

            if (this.filters.company.value) {
                filtersApi.companies = [this.filters.company.value];
            }

            if (this.filters.pdv.value) {
                filtersApi.pdvs = [this.filters.pdv.value];
            }

            if (this.filters.users.value) {
                filtersApi.users = [this.filters.users.value];
            }
        }

        return filtersApi;
    }


    open(): void {
        this.visible = true;
        if (!['', 'general'].includes(this.profile) && this.filters.distributor.list.length == 0) {
            this.cascadeLoadFilters();
        }
    }

    close(): void {
        this.visible = false;
    }

    getEntityId(entity: any) {
        switch (this.profile) {
            case 'distributor':
                return entity.id_distribuidor;
            case 'group':
                return entity.id_grupo;
            case 'merchan':
                return entity.id_merchan;
            case 'company':
                return entity.id_empresa;
            case 'pdv':
                return entity.id_pdv;
            case 'user':
                return entity.id_usuario;
        }
        return ''
    }

    setEntityIdPassValue(entity: any, id: number) {
        switch (this.profile) {
            case 'distributor':
                entity.id_pass_value = id;
                return '';
            case 'group':
                entity.id_grupo = id;
                return '';
            case 'merchan':
                entity.id_merchan = id;
                return '';
            case 'company':
                entity.id_empresa = id;
                return '';
            case 'pdv':
                entity.id_pdv = id;
                return '';
        }
        return ''
    }

    getEntityName(entity: any) {
        switch (this.profile) {
            case 'distributor':
                return entity.id_distribuidor_value;
            case 'group':
                return entity.id_grupo_value;
            case 'merchan':
                return entity.id_merchan_value;
            case 'company':
                return entity.id_empresa_value;
            case 'pdv':
                return entity.id_pdv_value;
            case 'user':
                return entity.id_usuario_value;
        }
        return ''
    }

    editGeneralPermission(value: any) {
        // console.log(value, this.selectedPermission, this.profile);
        this.permissionsService.editPassValue(this.profile, this.selectedPermission.id_pass, value).subscribe(data => {
            //console.log(data)
        })
    }

    translateProfile() {
        switch (this.profile) {
            case 'distributor':
                return 'distribuidor';
            case 'group':
                return 'grupo';
            case 'merchan':
                return 'merchan';
            case 'company':
                return 'empresa';
            case 'pdv':
                return 'pdv';
            case 'user':
                return 'usuario';
        }
        return this.profile
    }

    editPermission(value: any, entity: any, entityIndex: number) {
        this.selected_pass_list[entityIndex] = value;
        if (value === '' || value === -1) {
            value = null;
        }

        this.permissionsService.editPassValue(this.translateProfile(), this.selectedPermission.id_pass, value, this.getEntityId(entity)).subscribe(data => {

        })
    }

    setPermission(value: any, entity: any, entityIndex: number) {
        this.selected_pass_list[entityIndex] = value;
        if (value === '' || value === -1) {
            value = null;
        }
        this.permissionsService.editPassValue(this.translateProfile(), this.selectedPermission.id_pass, value, entity.id).subscribe(data => {

        })
    }

    checkSelected(value: any, entity: any, entityIndex: number) {

        if (this.selected_pass_list[entityIndex]) {
            return this.selected_pass_list[entityIndex] === value;
        } else {
            return value === entity.id_pass_value
        }
    }

    includedInValuesFilter(value: any) {
        return this.filters.values.findIndex((el: any) => el.value == value) > -1
    }


    toggleFilterValue(value: any, label: string) {
        const index = this.filters.values.findIndex((el: any) => el.value == value);
        if (index > -1) {
            this.filters.values.splice(index, 1)
        } else {
            this.filters.values.push({
                value: value,
                label: label
            })
        }
    }

    deleteFilter(value: any, type: string) {
        if (this.filters[type]) {
            if (type === 'values') {
                const index = this.filters[type].findIndex((el: any) => el.value == value)
                if (index > -1) {
                    this.filters[type].splice(index, 1)
                }
            } else {
                this.filters[type].value = null;
                this.filters[type].label = '';
                this.filters[type].list = [];
            }
            this.doSearch();
        }
    }


    loadDistributors() {
        // ['', 'general', 'distributor', 'group', 'merchan', 'company', 'pdv']
        if (['', 'general'].includes(this.profile)) {
            return;
        }
        this.filters.distributor.list = [];
        this.loadingFilters = true;
        this.permissionsService.getDistributors().subscribe(data => {
            this.filters.distributor.list = data;
            if (this.initialFilters.distributor.list.length === 0) {
                this.initialFilters.distributor.list = data;
            }
        }).add(() => this.loadingFilters = false)
    }

    loadGroups() {
        if (['', 'general', 'distributor'].includes(this.profile)) {
            return;
        }
        this.filters.group.list = [];
        this.loadingFilters = true;
        this.permissionsService.getGroups(this.applyFilters()).subscribe(data => {
            this.filters.group.list = data;
            if (this.initialFilters.group.list.length === 0) {
                this.initialFilters.group.list = data;
            }
        }).add(() => this.loadingFilters = false)
    }

    loadMerchans() {
        if (['', 'general', 'distributor', 'group'].includes(this.profile)) {
            return;
        }
        this.loadingFilters = true;
        this.filters.merchan.list = [];
        this.permissionsService.getMerchans(this.applyFilters()).subscribe(data => {
            this.filters.merchan.list = data;
            if (this.initialFilters.merchan.list.length === 0) {
                this.initialFilters.merchan.list = data;
            }
        }).add(() => this.loadingFilters = false)
    }

    loadCompanies() {
        if (['', 'general', 'distributor', 'group', 'merchan'].includes(this.profile)) {
            return;
        }
        this.loadingFilters = true;
        this.filters.company.list = []
        this.permissionsService.getCompanies(this.applyFilters()).subscribe(data => {
            this.filters.company.list = data;
            if (this.initialFilters.company.list.length === 0) {
                this.initialFilters.company.list = data;
            }
        }).add(() => this.loadingFilters = false)
    }

    loadPdvs() {
        if (['', 'general', 'distributor', 'group', 'merchan', 'pdv'].includes(this.profile)) {
            return;
        }
        this.loadingFilters = true;
        this.filters.pdv.list = []
        this.permissionsService.getPdvs(this.applyFilters()).subscribe(data => {
            this.filters.pdv.list = data;
            if (this.initialFilters.pdv.list.length === 0) {
                this.initialFilters.pdv.list = data;
            }
        }).add(() => this.loadingFilters = false)
    }

    loadUsers() {
        if (['', 'general', 'distributor', 'group', 'merchan', 'pdv'].includes(this.profile)) {
            return;
        }
        this.loadingFilters = true;
        this.filters.users.list = []
        this.permissionsService.getUsers(this.applyFilters()).subscribe(data => {
            this.filters.users.list = data;
            if (this.initialFilters.users.list.length === 0) {
                this.initialFilters.users.list = data;
            }
        }).add(() => this.loadingFilters = false)
    }


    addFilter(value: any, type: string) {
        if (type !== 'pdv' || (type == 'pdv' && this.profile == 'user') ) {
            if (type != 'users')
                this.filters['pdv'].value = '';

            this.filters[type].value = value;
            const elKey = this.profile === 'user' ? 'name' : 'id';

            let el = this.filters[type].list.find((e: any) => e[elKey] === value || e['id'] === value);

            if (type === 'users' && this.profile === 'company') {
                el = this.filters[type].list.find((e: any) => e['name'] === value);
            }

            if (el) {
                this.filters[type].label = el.name;
                if(type == 'pdv' && this.profile == 'user'){
                    this.filters[type].label = "ID PDV: " + value;
                }
            }

            this.cascadeLoadFilters(type);

        } else if (type === 'pdv') {
            this.filters[type].value = value;
            this.filters[type].label = "ID PDV: " + value;
            this.filters.distributor.list = this.initialFilters.distributor.list;
            this.filters.distributor.value = null;
            this.filters.group.list = this.initialFilters.group.list;
            this.filters.group.value = null;
            this.filters.merchan.list = this.initialFilters.merchan.list;
            this.filters.merchan.value = null;
            this.filters.company.list = this.initialFilters.company.list;
            this.filters.company.value = null;
        } 
       

    }


    cascadeLoadFilters(type: any = null) {
        const s = this.selectedPermission;

        if (!type) {
            this.loadDistributors();
        }

        if (!type || ['distributor'].includes(type)) {
            if (s.grupo == 1 || s.merchan == 1 || s.empresa == 1 || s.pdv == 1) {
                this.loadGroups()
            }

        }

        if (!type || ['distributor', 'group'].includes(type)) {
            if (s.merchan == 1 || s.empresa == 1 || s.pdv == 1) {
                this.loadMerchans()
            }
        }

        if (!type || ['distributor', 'group', 'merchan'].includes(type)) {
            if (s.empresa == 1 || s.pdv == 1) {
                this.loadCompanies()
            }
        }

        if ((this.profile == 'user' && !type) || ['distributor', 'group', 'merchan', 'company'].includes(type)) {
            if (s.pdv == 1 || s.usuario == 1) {
                this.loadPdvs()
            }
        }

        if ((this.profile == 'user' && !type) || ['distributor', 'group', 'merchan', 'company', 'pdv'].includes(type)) {
            if (s.usuario == 1) {
                this.loadUsers()
            }
        }
    }

    drawerWidth(): string {
        if (this.screenWidth < 992) {
            return "100%";
        } else {
            return "440px";
        }
    }

}
