import {Component, Input, OnInit} from '@angular/core';
import {PermissionsService} from "../../../services/http/herramientas/permissions.service";
import {catchError, finalize, of, take, tap} from "rxjs";

@Component({
    selector: 'app-modal-change-user-commission',
    templateUrl: './modal-change-user-commission.component.html',
    styleUrls: ['./modal-change-user-commission.component.scss']
})
export class ModalChangeUserCommissionComponent implements OnInit {
    @Input() user: any

    isVisible = false;
    isLoading = false
    showMsgError = false;
    msgError = '';

    constructor(
        private permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    changeCommission = () => {
        const body = {
            user_id: this.user.id_user,
            allow_commission: this.user.user_commissions != '1'
        }

        this.isLoading = true
        this.permissionsService.setCommissions(body)
            .pipe(
                take(1),
                tap(response => {
                    this.user.user_commissions = this.user.user_commissions == '1' ? '0' : '1';
                    this.handleOk();
                }),
                catchError(error => {
                    this.showMsgError = true;
                    this.msgError = error.error?.error?.msg_complete ?? 'Ha ocurrido un error al realizar el cambio en comisiones.';
                    return of({});
                }),
                finalize(() => this.isLoading = false)
            )
            .subscribe()
    }
}
