<div style="width: 100%;" *ngIf="filters.length > 0">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <ng-container *ngFor="let filter of filters; let i = index">

            <div [ngStyle]="{'display': i < maxFiltersVisible || expandFilters ? 'inherit' : 'none'}">
                <div class="form-group" *ngIf="filter.type === 'text'">
                    <input id="filter_text_{{i}}" formControlName="{{filter.name}}" type="text" oninvalid="" required="" [ngClass]="{'has-value': form.get(filter.name)?.value}">
                    <label for="filter_text_{{i}}" class="control-label flex-content f-between" style="width: 100%">
                        <div>
                            <span class=""><b>{{ getLabel(filter) }}</b></span>
                        </div>
                    </label><i class="bar"></i>
                </div>

                <div class="form-group" *ngIf="filter.type === 'number'">
                    <input id="filter_number_{{i}}" formControlName="{{filter.name}}" type="number"
                           min="{{filter?.min}}"
                           max="{{filter?.max}}"
                           step="{{filter?.step}}" oninvalid="" required=""
                           [ngClass]="{'has-value': form.get(filter.name)?.value}">
                    <label for="filter_number_{{i}}" class="control-label flex-content f-between" style="width: 100%">
                        <div>
                            <span class=""><b>{{ getLabel(filter) }}</b></span>
                        </div>
                    </label><i class="bar"></i>
                </div>

                <div class="form-group" *ngIf="filter.type === 'from-to'">
                    <label class="control-label flex-content f-between" style="width: 100%">
                        <div>
                            <span class=""><b>{{ getLabel(filter) }}</b></span>
                        </div>
                    </label>
                    <br>
                    <br>
                    <div nz-row [nzGutter]="0">
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzLg]="12" [nzMd]="12" [nzLg]="24" [nzXl]="24" [nzXXl]="24" style="margin-bottom: 15px;">
                            <input id="filter_fromto_from_{{i}}" formControlName="{{filter.name_from}}" type="date" (change)="validateDateRange(filter.name_from, filter.name_to)">
                            <label for="filter_fromto_from_{{i}}" class="control-label flex-content f-between" style="width: 100%">
                                <div>
                                    <span class="label-text"><b>Desde</b></span>
                                </div>
                            </label><i class="bar"></i>
                        </div>
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzLg]="12" [nzMd]="12" [nzLg]="24" [nzXl]="24" [nzXXl]="24">
                            <input id="filter_fromto_to_{{i}}" formControlName="{{filter.name_to}}" type="date" [min]="form.controls[filter.name_from].value" (change)="validateDateRange(filter.name_from, filter.name_to)">
                            <label for="filter_fromto_to_{{i}}" class="control-label flex-content f-between" style="width: 100%">
                                <div>
                                    <span class=""><b>Hasta</b></span>
                                </div>
                            </label><i class="bar"></i>
                        </div>
                    </div>
                </div>


                <div class="form-group" [ngClass]="{'form-selected': showLabelSelect(getLabel(filter))}" *ngIf="filter.type === 'select'">
                    <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect(getLabel(filter))">
                        <b>{{ getLabel(filter) }}</b>
                    </label>
                    <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect(getLabel(filter),false)" (nzFocus)="focusSelect(getLabel(filter),true)" [nzPlaceHolder]="getLabel(filter)" formControlName="{{filter.name}}" nzBorderless style="width: 100%;" [nzLoading]="filter.loading" [nzDisabled]="filter.loading" (ngModelChange)="changeFilter(filter)">
                        <nz-option [nzValue]="filter?.default_value?.value" nzLabel="{{ filter?.default_value?.name }}" *ngIf="filter.default_value"></nz-option>
                        <nz-option [nzValue]="option.value" nzLabel="{{ option.name }}" *ngFor="let option of filter.options"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>

        </ng-container>

        <!-- <div class="" *ngIf="filters.length > maxFiltersVisible">
            <p class="font s-16 c-primary" style="cursor: pointer" (click)="expandFilters = !expandFilters">{{ expandFilters ? '- Ver menos filtros' : '+ Ver más filtros'}}</p>
        </div> -->

        <div class="form-group form-group-footer margins m-t-30">
            <div class="flex-content a-center">
                <button nz-button class="c-dark size-large" nzBlock>Buscar</button>
            </div>
        </div>
    </form>

</div>
