import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from "ng-apexcharts";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// NG_ZORRO
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';


import { HeaderComponent } from './components/header/header.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { OperationsComponent } from './charts/operations/operations.component';
import { MenuDesktopComponent } from './components/menu-desktop/menu-desktop.component';
import { PrepaidComponent } from './charts/prepaid/prepaid.component';
import { CommissionerChartComponent } from './charts/commissioner-chart/commissioner-chart.component';
import { AmenaChartComponent } from './charts/amena-chart/amena-chart.component';
import { AmenaFixedChartComponent } from './charts/amena-fixed-chart/amena-fixed-chart.component';
import { AmenaConvergentChartComponent } from './charts/amena-convergent-chart/amena-convergent-chart.component';
import { TabReportsComponent } from './components/tab-reports/tab-reports.component';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { ModalSaveReportComponent } from './components/modal-save-report/modal-save-report.component';
import { FormActivationRequestComponent } from './components/form-activation-request/form-activation-request.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';
import { BarSearchClientsComponent } from './components/bar-search-clients/bar-search-clients.component';
import { PrepaidListComponent } from './components/prepaid-list/prepaid-list.component';
import { PrepaidFormComponent } from './components/prepaid-form/prepaid-form.component';
import { PostpaidListComponent } from './components/postpaid-list/postpaid-list.component';
import { ListContractedProductsComponent } from './components/list-contracted-products/list-contracted-products.component';
import { DataClientCardComponent } from './components/data-client-card/data-client-card.component';
import { SavedReportsComponent } from './components/saved-reports/saved-reports.component';
import { ModalFormComponent } from './components/modal-form/modal-form.component';
import { MatDialogModule } from "@angular/material/dialog";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ModalRebrandingComponent } from './components/modal-rebranding/modal-rebranding.component';
import { ModalUserPdvComponent } from './components/modal-user-pdv/modal-user-pdv.component';
import { DetailsLateralMenuComponent } from './components/details-lateral-menu/details-lateral-menu.component';
import { ModalMoreInfoPdvComponent } from './components/modal-more-info-pdv/modal-more-info-pdv.component';
import { DetailOrderComponent } from './components/detail-order/detail-order.component';
import { LoaderComponent } from './components/loader/loader.component';
import { GenericErrorComponent } from './components/generic-error/generic-error.component';
import { StatusOrderComponent } from './components/status-order/status-order.component';
import { PdvTableComponent } from './components/pdv-table/pdv-table.component';
import { PdvFormTableComponent } from './components/pdv-form-table/pdv-form-table.component';
import { CompanyFormTableComponent } from './components/company-form-table/company-form-table.component';
import { CompanyTableComponent } from './components/company-table/company-table.component';
import { UsersCompanyTableComponent } from './components/users-company-table/users-company-table.component';
import { ModalObservationComponent } from './components/modal-observation/modal-observation.component';
import { ModalChangeUserComponent } from './components/modal-change-user/modal-change-user.component';
import { ModalConfirmDeleteComponent } from './components/modal-confirm-delete/modal-confirm-delete.component';
import { ModalCarouselImagesComponent } from './components/modal-carousel-images/modal-carousel-images.component';
import { PermissionsStepDeliveryComponent } from './components/permissions-step-delivery/permissions-step-delivery.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CustomPipesModule } from '../core/custom-pipes/custom-pipes.module';
import { ObservationsComponent } from './components/observations/observations.component';
import { ModalChangePasswordComponent } from './components/modal-change-password/modal-change-password.component';
import { ModalAddUserComponent } from './components/modal-add-user/modal-add-user.component';
import { ModalGlobalChangePasswordComponent } from './components/modal-global-change-password/modal-global-change-password.component';
import { ModalCancelOrderComponent } from './components/modal-cancel-order/modal-cancel-order.component';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { ModalChangeEmailComponent } from './components/modal-change-email/modal-change-email.component';
import { ComponentsModule } from '../core/components/components.module';
import { OnlynumbersDirective } from '../core/directives/onlynumbers.directive';
import { BlockPasteDirective } from '../core/directives/block-paste.directive';
import { ModalChangeSupervisorComponent } from './components/modal-change-supervisor/modal-change-user.component';
import { ModalConfirmDeleteSupervisorComponent } from './components/modal-confirm-delete-supervisor/modal-confirm-delete-supervisor.component';
import { ModalChangeUserCommissionComponent } from './components/modal-change-user-commission/modal-change-user-commission.component';


@NgModule({
    declarations: [
        HeaderComponent,
        NavMenuComponent,
        OperationsComponent,
        MenuDesktopComponent,
        PrepaidComponent,
        TabReportsComponent,
        CommissionerChartComponent,
        AmenaChartComponent,
        AmenaFixedChartComponent,
        AmenaConvergentChartComponent,
        TabReportsComponent,
        MenuMobileComponent,
        ModalSaveReportComponent,
        FormActivationRequestComponent,
        ClientsListComponent,
        BarSearchClientsComponent,
        PrepaidListComponent,
        PrepaidFormComponent,
        PostpaidListComponent,
        ListContractedProductsComponent,
        DataClientCardComponent,
        SavedReportsComponent,
        ModalFormComponent,
        BreadcrumbsComponent,
        ModalRebrandingComponent,
        ModalRebrandingComponent,
        ModalUserPdvComponent,
        DetailsLateralMenuComponent,
        ModalMoreInfoPdvComponent,
        DetailOrderComponent,
        LoaderComponent,
        GenericErrorComponent,
        StatusOrderComponent,
        PdvTableComponent,
        PdvFormTableComponent,
        CompanyFormTableComponent,
        CompanyTableComponent,
        UsersCompanyTableComponent,
        ModalObservationComponent,
        ModalChangeUserComponent,
        ModalConfirmDeleteComponent,
        ModalCarouselImagesComponent,
        PermissionsStepDeliveryComponent,
        ObservationsComponent,
        ModalChangePasswordComponent,
        ModalAddUserComponent,
        ModalGlobalChangePasswordComponent,
        ModalCancelOrderComponent,
        ModalErrorComponent,
        ModalChangeEmailComponent,
        OnlynumbersDirective,
        BlockPasteDirective,
        ModalChangeSupervisorComponent,
        ModalConfirmDeleteSupervisorComponent,
        ModalChangeUserCommissionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        NzButtonModule,
        NzDropDownModule,
        NzSwitchModule,
        NzGridModule,
        NzProgressModule,
        NzTabsModule,
        NzIconModule,
        NzCollapseModule,
        NzBreadCrumbModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        NzRadioModule,
        NzModalModule,
        NzDividerModule,
        NzCheckboxModule,
        NzListModule,
        NzBadgeModule,
        NzDatePickerModule,
        NzToolTipModule,
        MatDialogModule,
        HttpClientModule,
        NzDrawerModule,
        NzUploadModule,
        RouterModule,
        MatStepperModule,
        CustomPipesModule,
        ComponentsModule
    ],
    exports: [
        // Components
        HeaderComponent,
        NavMenuComponent,
        OperationsComponent,
        MenuDesktopComponent,
        PrepaidComponent,
        TabReportsComponent,
        ModalSaveReportComponent,
        FormActivationRequestComponent,
        ClientsListComponent,
        BarSearchClientsComponent,
        PrepaidListComponent,
        PrepaidFormComponent,
        PostpaidListComponent,
        ListContractedProductsComponent,
        DataClientCardComponent,
        SavedReportsComponent,
        ModalFormComponent,
        BreadcrumbsComponent,
        ModalRebrandingComponent,
        ModalUserPdvComponent,
        DetailsLateralMenuComponent,
        ModalMoreInfoPdvComponent,
        DetailOrderComponent,
        LoaderComponent,
        GenericErrorComponent,
        StatusOrderComponent,
        PdvTableComponent,
        PdvFormTableComponent,
        CompanyFormTableComponent,
        CompanyTableComponent,
        UsersCompanyTableComponent,
        ModalObservationComponent,
        ModalChangeUserComponent,
        ModalConfirmDeleteComponent,
        ModalCarouselImagesComponent,
        ModalCancelOrderComponent,
        PermissionsStepDeliveryComponent,
        ObservationsComponent,
        // Charts
        CommissionerChartComponent,
        AmenaChartComponent,
        AmenaFixedChartComponent,
        AmenaConvergentChartComponent,
        // Module
        FormsModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        NzButtonModule,
        NzDropDownModule,
        NzSwitchModule,
        NzGridModule,
        NzProgressModule,
        NzTabsModule,
        NzIconModule,
        NzCollapseModule,
        NzBreadCrumbModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        NzRadioModule,
        NzModalModule,
        NzDividerModule,
        NzCheckboxModule,
        NzListModule,
        NzBadgeModule,
        NzDrawerModule,
        NzToolTipModule,
        NzUploadModule,
        MatStepperModule,
        CustomPipesModule,
        ComponentsModule,
        OnlynumbersDirective,
        BlockPasteDirective,
        ModalChangeSupervisorComponent,
        ModalConfirmDeleteSupervisorComponent
    ]
})
export class SharedModule { }
