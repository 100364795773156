import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})

export class PermissionsService extends ApiService {
    private validValues = ['general', 'distributor','group','merchan','company','pdv'];
    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    getPermissionsMaster() {
        return this.http.get<any>(this.endpoint + '/pass_config/master', {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    // obtiene valores de un permiso concreto para distintas entidades, acepta filtros para las entidades. P.Ej: obtener valores del permiso 46 para los merchans que encajen en el filtro
    getFilteredPermissionValuesList(entityType: string, id: number, filters: any = {}) {
        if (!this.validValues.includes(entityType)) {
            console.log('PermissionsService.getFilteredPermissionValuesList: Invalid entity type '+entityType);
        }


        return this.http.post<any>(this.endpoint + '/pass_config/'+entityType+'/pass/'+id, {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }


    // para una entidad concreta, obtener su lista de permisos (p ej: lista de permisos de la pdv con id 58956
    getEntityPermissionsList(entityType: string, entityId: any) {
        return this.http.get<any>(this.endpoint + 'pass_config/' + entityType + '/' + entityId, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }



    editPassValue(entityType: string, id_pass:any, value: any, id_entity: any = null) {
        if (!this.validValues.includes(entityType)) {
            console.log('editPassValue.getFilteredPermissionValuesList: Invalid entity type '+entityType);
        }

        const data: any = {
            id_pass: id_pass,
            // valor: value
        }

        if (value !== null) {
            data.valor = value;
        }

        if (id_entity) {
            data.id = id_entity;
        }

        return this.http.post<any>(this.endpoint + 'company/pass_config/tp/' + entityType, data, {headers: this.getHeaders()})
    }

    getDistributors(filters: any = {}) {
        return this.http.post(environment.digoApiEndpoint + 'pass_config/distributors', {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getGroups(filters: any = {}) {
        return this.http.post(environment.digoApiEndpoint + 'pass_config/groups', {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getMerchans(filters: any = {}) {
        return this.http.post(environment.digoApiEndpoint + 'pass_config/merchans', {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getCompanies(filters: any = {}) {
        return this.http.post(environment.digoApiEndpoint + 'pass_config/companies', {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getPdvs(filters: any = {}) {
        return this.http.post(environment.digoApiEndpoint + 'pass_config/pdvs', {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getUsers(filters: any = {}) {
        return this.http.post(environment.digoApiEndpoint + 'pass_config/users', {filters: filters}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    setCommissions(body: any = {}) {
        return this.http.post(`${environment.digoApiEndpoint}pass_config/user/setCommissions`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }
}
