<div class="container-max">
  <app-breadcrumbs
          [breacrumbList]="['Herramientas', 'Reset 2FA']"
          [title]="'Herramientas <span class=\'font c-primary\'>Reset 2FA</span>'"
  ></app-breadcrumbs>
  <div class="inner-content-form p-0" style="position: relative; top: 40px; left: 5px; z-index: 1" *ngIf="!loading && !error">
    <div class="digo-card big-card">
      <form nz-form [formGroup]="reset2faForm" class="login-form" (ngSubmit)="submitForm()">
        
        <div class="form-group">
          <div nz-row nzGutter="50">
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="24" nzXs="24" nzSm="24">
              <div class="form-group margins m-b-50">
                  <input type="text" oninvalid="" required="" formControlName="username" [ngClass]="{'has-value': true}">
                  <label for="input" class="control-label flex-content f-between" style="width: 100%">
                      <div>
                          <span class=""><b>Usuario*</b></span>
                      </div>
                  </label><i class="bar"></i>
                  <span class="error-form" *ngIf="reset2faForm.get('username')?.touched && reset2faForm.get('username')?.errors?.['required']">
                      Campo obligatorio
                  </span>
              </div>
            </div>
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="24" nzXs="24" nzSm="24">
              <div class="form-group margins m-b-50">
                  <input type="text" oninvalid="" required="" formControlName="numTask" placeholder="DIGO-1234" [ngClass]="{'has-value': true}">
                  <label for="input" class="control-label flex-content f-between" style="width: 100%">
                      <div>
                          <span class=""><b>Número de tarea*</b></span>
                      </div>
                  </label><i class="bar"></i>
                  <span class="error-form" *ngIf="reset2faForm.get('numTask')?.touched && reset2faForm.get('numTask')?.errors?.['required']">
                      Campo obligatorio
                  </span>
              </div>
            </div>
            
            </div>
              <div class="footer-inner-modal">
                <div class="form-group form-group-footer">
                    <div class="flex-content a-center">
                        <button nz-button class="c-primary size-large" nzBlock>Resetear</button>
                    </div>
                </div>
              </div>
          </div>
      </form>
    </div>
  </div>

</div>